<template>
	<v-main class="pa-2">
		<v-row>
			<v-col>
				<h1 class="light-blue--text text--darken-2 text-uppercase text-center">Услуги</h1>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-9"><v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @change='makeUrl' single-line hide-details></v-text-field></v-col>
			<v-col class="col-3">
				<v-btn fab dark color="light-blue darken-2" href="http://lk.vetlight.ru/finance/price/1/" ><v-icon dark >mdi-download</v-icon></v-btn>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<v-data-iterator :items="price" :search="search" page.sync="1" :items-per-page.sync="perpage" group-by="services_category" loading-text="Загрузка прайса" loading>
			<template v-slot:default="props">
				<div class="pa-0 ma-0" v-for="item in props.items" :key="item">
					<v-row>
						<v-col class="col-12 col-sm-9">{{ item.services_name }}</v-col>
						<v-col class="col-12 col-sm-3">{{ item.services_price }} &#8381;</v-col>
					</v-row>
					<v-divider></v-divider>
				</div>
			</template>
		</v-data-iterator>

		<v-overlay :value="loading">
			<v-row><v-col class="col-12">
				<v-img src="/img/loading_dog_small.gif"></v-img>
			</v-col></v-row>
		</v-overlay>

		
	</v-main>
</template>

<script type="text/javascript">


export default {
	name: 'PriceComponent',
	props: {
		count: { type:[String, Number], default:50},
	},
	data () {
		return {
			perpage: 40,
			categories: [],
			category:[],
			search:'',
		}
	},
	computed: {
		loading() { return this.$store.getters.getPriceLoad },
		price() { return this.$store.getters.getPrice },
	},
	methods: {
		makeUrl(value) {
			// Устанавливаем URL
			this.$router.push('/price?search='+value)
		},
		getUrl(){
			// Получаем поле search из URL
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const search = urlParams.get('search')
			this.search = search
		},
		filterOnlyCapsText (value, search, item) {
			console.log(value, search, item)
			return value != null && search != null && typeof value === 'string' && value.toString().toLocaleUpperCase().indexOf(search) !== -1 
		},
	},
	beforeMount() {
		//this.getPrice()
		this.$store.dispatch('uploadPriceAsync')
		this.getUrl()
	},
}
</script>