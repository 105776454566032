<template>
	<v-card>
		<PriceComponent count="20"></PriceComponent>
	</v-card>
</template>

<script type="text/javascript">
import PriceComponent from '@/components/PriceComponent_v2'

export default {
	name:'Price',
	components: {
		PriceComponent,
	},
}
</script>